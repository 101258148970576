import React from 'react'
import { Helmet } from 'react-helmet'
import './../styles/Home.css'
import './../styles/CardComponent.css'
import ContactForm from './ContactForm'
import logo from '../assets/logos/extra/logo-27.png'
import resourceimage from '../assets/resourcing_image.png'
import testingimage from '../assets/mobile-testing-illustration.png'
import appdevimage from '../assets/software_dev_image.png'
import EmblaCarousel from './EmblaCarousel'
import CardComponent from './CardComponent'
import OurServices from './OurServices'

const Home = () => (
  <>
    <Helmet>
      <title>QA Tech - Building Confidence Through Quality</title>
      <meta
        name="description"
        content="QA Tech offers comprehensive software testing and app development services to ensure the highest quality for your projects."
      />
      <meta name="keywords" content="software testing, app development, QA services, software consultancy" />
      <script type="application/ld+json">
        {`
          {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "QA Tech",
            "url": "https://qatechltd.com",
            "logo": "https://qatechltd.com/assets/logos/extra/logo-27.png",
            "sameAs": [
              "https://www.linkedin.com/company/qa-tech-consultancy"
            ],
            "contactPoint": {
              "@type": "ContactPoint",
              "email": "info@qatechltd.com",
              "contactType": "Sales"
            }
          }
        `}
      </script>
    </Helmet>
    <div className="body">
      <div className="home">
        <div className="content">
          <h1>Building Confidence Through Quality</h1>
          <a href="#contact-form" className="get-in-touch-button">
            Get in touch
          </a>
        </div>
        <div className="image-container">
          <img src={logo} alt="QA Tech Logo" className="home-image" />
        </div>
      </div>
      <div className="cards-container">
        <CardComponent
          title="App development"
          body="We build your app from the ground up with a test first approach"
          link="#"
          image={appdevimage}
          showButton={false} // Hide the button
        />
        <CardComponent
          title="Software Testing"
          body="Embedded software testing team and software testing solutions"
          link="#"
          image={testingimage}
          showButton={false} // Hide the button
        />
        <CardComponent
          title="Resourcing"
          body="Providing only the best candidates for your project. We have resources at all seniority levels to suit your needs and budget"
          image={resourceimage}
          showButton={false} // Hide the button
        />
      </div>
      <OurServices />
      <div className="carousel-container">
        <h2 style={{ color: 'black' }}>Who we've worked with</h2>
        <EmblaCarousel />
      </div>
      <div>
        <ContactForm />
      </div>
    </div>
  </>
)

export default Home
