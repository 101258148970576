import React from 'react'
import { Helmet } from 'react-helmet'
import './../styles/Services.css'

const About = () => (
  <>
    <Helmet>
      <title>About Us - QA Tech</title>
      <meta
        name="description"
        content="Learn more about QA Tech, our mission, values, and the team behind our success."
      />
      <meta name="keywords" content="about QA Tech, software consultancy, software testing, app development" />
    </Helmet>
    <div className="services">
      <h1>About Us</h1>
      <p>Information about the company...</p>
    </div>
  </>
)

export default About
