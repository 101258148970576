import React from 'react'
import './../styles/CardComponent.css'

const CardComponent = ({ title, body, image }) => {
  return (
    <div className="card">
      {image && <img src={image} alt={title} className="card-image" />}
      <div className="card-content">
        <h2 className="card-title">{title}</h2>
        <p className="card-body">{body}</p>
      </div>
    </div>
  )
}

export default CardComponent
