import React from 'react'
import { Helmet } from 'react-helmet'
import './../styles/Services.css'

const Services = () => (
  <>
    <Helmet>
      <title>Our Services - QA Tech</title>
      <meta
        name="description"
        content="Discover the comprehensive range of services offered by QA Tech, including software testing, app development, and more."
      />
      <meta name="keywords" content="software testing, app development, QA services, software consultancy" />
    </Helmet>
    <div className="services">
      <h1>Our Services</h1>
      <p>We offer comprehensive testing and development services...</p>
    </div>
  </>
)

export default Services
