import React from 'react'
import { Helmet } from 'react-helmet'
import './../styles/PrivacyPolicy.css'

const PrivacyPolicy = () => (
  <>
    <Helmet>
      <title>Privacy Policy - QA Tech</title>
      <meta
        name="description"
        content="Read the privacy policy of QA Tech to understand how we handle your data and protect your privacy."
      />
      <meta name="keywords" content="QA Tech privacy policy, data protection, privacy" />
    </Helmet>
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p>Last updated: 15/05/2024</p>
      <p>
        QA Tech Ltd ("we", "our", "us") is committed to protecting and respecting your privacy. This Privacy Policy
        explains how we collect, use, disclose, and safeguard your information when you visit our website , use our
        services, or engage with us in other ways. Please read this policy carefully to understand our views and
        practices regarding your personal data and how we will treat it.
      </p>

      <h2>1. Information We Collect</h2>
      <p>We may collect and process the following data about you:</p>
      <ul>
        <li>
          <strong>Personal Identification Information:</strong> Name, Email address, Company name, Phone number, Address
        </li>
        <li>
          <strong>Technical Data:</strong> Internet Protocol (IP) address, Browser type and version, Time zone setting,
          Browser plug-in types and versions, Operating system and platform
        </li>
        <li>
          <strong>Usage Data:</strong> Information about how you use our website and services
        </li>
        <li>
          <strong>Marketing and Communications Data:</strong> Your preferences in receiving marketing from us and your
          communication preferences
        </li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We use the information we collect in the following ways:</p>
      <ul>
        <li>To provide, operate, and maintain our website and services</li>
        <li>To improve, personalize, and expand our website and services</li>
        <li>To understand and analyze how you use our website and services</li>
        <li>To develop new products, services, features, and functionality</li>
        <li>
          To communicate with you, either directly or through one of our partners, including for customer service, to
          provide you with updates and other information relating to the website, and for marketing and promotional
          purposes
        </li>
        <li>To process your transactions and manage your orders</li>
        <li>To send you emails</li>
        <li>To find and prevent fraud</li>
        <li>To comply with legal obligations</li>
      </ul>

      <h2>3. How We Share Your Information</h2>
      <p>
        We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless
        we provide users with advance notice. This does not include website hosting partners and other parties who
        assist us in operating our website, conducting our business, or serving our users, so long as those parties
        agree to keep this information confidential. We may also release information when its release is appropriate to
        comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety.
      </p>

      <h2>4. Security of Your Information</h2>
      <p>
        We use administrative, technical, and physical security measures to help protect your personal information.
        While we have taken reasonable steps to secure the personal information you provide to us, please be aware that
        despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be
        guaranteed against any interception or other type of misuse.
      </p>

      <h2>5. Your Data Protection Rights</h2>
      <p>Depending on your location, you may have the following rights regarding your personal data:</p>
      <ul>
        <li>The right to access – You have the right to request copies of your personal data.</li>
        <li>
          The right to rectification – You have the right to request that we correct any information you believe is
          inaccurate. You also have the right to request that we complete the information you believe is incomplete.
        </li>
        <li>
          The right to erasure – You have the right to request that we erase your personal data, under certain
          conditions.
        </li>
        <li>
          The right to restrict processing – You have the right to request that we restrict the processing of your
          personal data, under certain conditions.
        </li>
        <li>
          The right to object to processing – You have the right to object to our processing of your personal data,
          under certain conditions.
        </li>
        <li>
          The right to data portability – You have the right to request that we transfer the data that we have collected
          to another organization, or directly to you, under certain conditions.
        </li>
      </ul>
      <p>
        If you make a request, we have one month to respond to you. If you would like to exercise any of these rights,
        please contact us at our provided contact information.
      </p>

      <h2>6. Changes to This Privacy Policy</h2>
      <p>
        We may update this privacy policy from time to time in order to reflect, for example, changes to our practices
        or for other operational, legal, or regulatory reasons. We will notify you of any changes by posting the new
        Privacy Policy on this page.
      </p>

      <h2>7. Contact Us</h2>
      <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us:</p>
      <p>
        QA Tech Consultancy Ltd
        <br />
        Email: info@qatechltd.co.uk
        <br />
        London
      </p>
    </div>
  </>
)

export default PrivacyPolicy
