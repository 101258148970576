import React from 'react'
import { Helmet } from 'react-helmet'
import './../styles/Services.css'

const Community = () => (
  <>
    <Helmet>
      <title>Community - QA Tech</title>
      <meta
        name="description"
        content="Join the QA Tech community and connect with like-minded professionals in the software industry."
      />
      <meta name="keywords" content="QA Tech community, software professionals, networking" />
    </Helmet>
    <div className="services">
      <h1>Community</h1>
      <p>Information about the community...</p>
    </div>
  </>
)

export default Community
